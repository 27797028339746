import React, { useEffect, createRef } from "react"
import LayoutHrbridReport from "../components/layouthybridreport"
import NavigationTeam from "../components/navigationteam"
import ReportFormTamilnadu from "../components/ReportFormTamilnadu"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import "../styles/hybrid.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
import SEOHeader from "../components/seo-header"
import "lazysizes"
import HybridImage from "../img/people-meeting-seminar-office-concept.jpg"
import Navigation from "../components/navigation"
export default function TamilnaduCoworking() {
  return (
    <div>
      <SEOHeader
        title="A Comprehensive Report on How Chennai's Coworking Industry is Evolving & UpgradingA comprehensive report on Tamilnadu"
        description="Tamil Nadu is experiencing a period of dynamic growth and transformation. From infrastructure advancements to flex spaces driving industries across sectors, the state is rapidly evolving."
        pinterest="true"
      ></SEOHeader>
      <Helmet></Helmet>
      <LayoutHrbridReport>
        <div className="hybridreport">
          <Navigation />
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
            </div>
          </div>
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <div className="TeamTextContent text-center">
                  {/* <h3
                    style={{
                      lineHeight: "1",
                      padding: "0",
                      marginBottom: "15px",
                    }}
                  ></h3> */}
                  <h2
                    style={{
                      lineHeight: "1",
                      padding: "0",
                      marginBottom: "15px",
                      fontWeight: "600",
                    }}
                  >
                    A comprehensive report on
                  </h2>
                  <h1
                    style={{
                      fontSize: "3.2em",
                      color: "#4f9fc8",
                      marginBottom: "15px",
                      padding: "0",
                      lineHeight: "1",
                    }}
                  >
                    Tamilnadu: A state with boundless potential for Flexspaces
                  </h1>
                  <p>Landscape, Factors & Forecasts</p>
                  <br></br>
                  <a
                    onClick={() => scrollTo("#signup")}
                    className="teamGetStarted"
                    style={{ background: "rgb(79, 159, 200)", color: "#fff" }}
                  >
                    Download
                  </a>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <br></br>
                <img
                  data-src="https://assets.gofloaters.com/chennai-managed-offices-report/tamilnadu-coworking-industry-report.png"
                  alt="Tamilnadu: A state with boundless potential for Flexspaces"
                  className="lazyload img-responsive"
                ></img>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>

        <div style={{ padding: "10px 0", background: "#fff" }}>
          <div className="container">
            <div className="row  teamPadding TeamsWorkFromHome"></div>
          </div>
        </div>

        <div style={{ background: "#fff" }}>
          <div>
            <div className="container">
              <ReportFormTamilnadu />
            </div>
          </div>
        </div>
      </LayoutHrbridReport>
    </div>
  )
}
